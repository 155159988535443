import React from 'react';
import './intro.css';
import bg from '../../assets/image.png';
import btnImg from '../../assets/cv.png';
// import { Link } from 'react-scroll';

const Intro = () => {
    return (
        <section id="intro">
        <div className="introContent">
            <span className="hello">Hello,</span>
            <span className="introText">I'm <span className="introName">Sai Aditya</span> <br />Research Enthusiast</span>
            <p className="introPara">one of 87 members of <br />the Economic Times Campus Stars 6.0 Class of 2022-23</p>
            <a className='CVlink' href="https://drive.google.com/file/d/1D7dKz47DeOtpKM-Nn75R-qQ0dA_FD0z8/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                <button className="btn">
                    <img src={btnImg} alt="Hire" className='btnImg'/>View CV
                </button>
            </a>
        </div>
        <img src={bg} alt="Profile" className="bg" />
    </section>
    
    );
}

export default Intro;