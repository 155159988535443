import "./styles/Extracurricular.css"

const activities = [
    {
        name: "Swimming",
        description: "Runner-up in Kendriya Vidyalaya Sangathan Mumbai-zone cluster swimming meet at IIT-Bombay. And Selected for KVS Mumbai Region State-Level Swim team."
    },
    {
        name: "Head coordinator at AmritaSREE",
        description: "Led AmritaSREE Kit distribution, empowering women through essential supplies in my role as head coordinator for self-reliance and empowerment."
    },
    {
        name: "Team Lead at Amritavarsham’70",
        description: "Managed a team of 8 for venue maintenance at Amritavarsham’70, focusing on waste segregation. Featured in a video interview on campus clean drive participation."
    }
]


export const ExtraCurricularPage = () => {
    return(
        <div className="ExtraCurricular">
            <div> 
                <h1> ExtraCurricular </h1>
            </div>
            <div className="Activities">
                {activities.map((activity, index) => (
                    <div className="ActivityBox">
                        <h3>{activity.name}</h3>
                        <div>
                        <p>{activity.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )

}