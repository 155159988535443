import React from 'react';
import './navbar.css';
import logo from '../../assets/logo.png';

export const NavbarLogo = () => {
    return (
        <nav className="navbar">
            <a href='/'><img src={logo} alt="Logo" className='logo'/></a>
            
        </nav>
    )
}

