// import Logo from "../assets/logo.png"

const styles = {
    Container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // maxWidth : "80%"
    },
    textBox:{
      display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign:"center",
        maxWidth : "60%"
    }
}

export const StartupsPage = () => {
    return(
        <div>
            <h1>Startups</h1>
            <div style={styles.Container}>
              <YouTubeVideoEmbed/>
              {/* <img src={Logo} alt="Project Lakshya"></img> */}
                  <h2>
                      Project LAKSHYA
                  </h2>
                  <h3><i>Co-Founder</i></h3>
                  <div style={styles.textBox}>
                    <p>A non-profit group initiative under Student Social Responsibility (SSR) to promote peer learning.</p>
                    <p>Beyond academic pursuits, I am deeply passionate about entrepreneurship. I am the co-founder of "LAKSHYA," a startup dedicated to democratizing access to quality academic advice. Our program has significantly impacted 30+ students in their academic career. Currently, I closely mentor 10 sophomores. With empathic listening and persuasive speaking skills, I have been invited as a guest speaker on multiple occasions, addressing audiences of 300+ students.</p>
                  </div>
                  {/* <p>Organized multiple hybrid sessions to provide academic support for peers and juniors. Received college recognition as one of the top initiatives of the year. LAKSHYA has now expanded to provide academic, career, and life-skills mentorship for freshmen. I have also been invited to multiple events to address my fellow juniors about the impact of undertaking a humanitarian initiative. Till now, this initiative has impacted 30+ students in their academic growth.</p> */}

            </div>
        </div>  
    )

}


const YouTubeVideoEmbed = () => {
  const videoId = 'hHYMTf-OanU';

  return (
    <div>
      <iframe
        title="YouTube Video"
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeVideoEmbed;