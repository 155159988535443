import "./styles/EducationalQual.css"
import Portfolio from "../assets/aditya/sai-aditya.jpeg"

const education = [
    // {
    //     name: "Cricket",
    //     description: "Vestiges are remnants of evolutionary history—“footprints” or “tracks,” as translated from the Latin vestigial. All species possess vestigial features, which range in type from anatomical to physiological to behavioral. More than 100 vestigial anomalies occur in humans. The following list explores 7 of them.",
    //     image: Portfolio
    // },
    {
        name: "Amrita Vishwa Vidyapeetham, Amritapuri, India",
        description: "I am currently pursuing B.Tech. in Electronics and Communication Engineering, standing out with my academic excellence. Demonstrating an exceptional commitment I have achieved a remarkable CGPA of 9.78 out of 10. My achievements extend beyond individual success, as I have secured the 1st position in a batch of 110 students. My academic journey reflects not only my intellectuality but also my ability to consistently outperform my peers on a broader scale.",
         image: Portfolio
    }
]


export const EducationPage = () => {
    return (
        <div className="EducationalQual">
            <div>
                <h1> Education </h1>
            </div>
            <div className="education">
                {education.map((education, index) => (
                    <div className="EduBox">
                        <article>
                            <div className="wrap">
                                <img alt="Education_Image" src={education.image} className="EduImage"></img>
                            </div>
                            <div>
                                <h3 className="EduTitle">{education.name}</h3>
                                <p className="EduDesc">{education.description}</p>
                            </div>
                        </article>
                    </div>
                ))}
            </div>
            
        </div>
    )

}