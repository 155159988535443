import "./styles/Research.css"
import Portfolio2 from "../../src/assets/aditya/IMG_6771.PNG";
import Portfolio1 from "../../src/assets/aditya/2dmaterials.jpeg";

const ResearchExperience = [
    {
        title: "Multi-Domain Energy Harvester for Medical Implants",
        timestamp: "June ’23 - September ’24",
        description: [
            "Selected as MITACS Research Intern at Intelligent Sensory Microsystems Lab. Worked under the Biomedical engineering team; one of North America’s best labs for Integrated Circuit designing.",
            "Utilized Altium Designer Electronic Design Automation software to design a compact PCB focusing on a multi-domain energy harvesting front end. Integrated 4 discrete domains—Photovoltaic, Thermoelectric, Bio-chemical, & Piezoelectric—into the PCB for efficient energy harvesting.",
            "Gained expertise in micro-soldering by manually performing micro-soldering of extremely minute components across the entire board.",
            "Rigorously tested the PCB under artificially simulated conditions to evaluate its performance and ensure functionality across all integrated energy domains.",
            "Verified the parallel performance of the circuit design, confirming that all four energy domains operate cohesively to maximize energy harvesting capabilities. Currently engaged in further reducing the form factor of the PCB, aiming to make it suitable for conducting animal testing."
        ],
        image: Portfolio2

    },
    {
        title: "2D Material-Based Devices",
        timestamp: "October ’23 - July ’24",
        description: [
            "Currently participating in an online mode by conducting the preliminary literature survey.",
            "Will be physically joining Dr. Das’s lab at Penn State from January 2024 onwards and will be actively working on 2D material-based devices.",
            "The core objective of the research is to demonstrate high accuracy inference of MNIST data using Artificial Neural Networks (NNs) and analyze the measurements of 2D Field Effect Transistors(FETs) to carry out benchmarking of their performance.",
            "The research goal includes material characterization, fabrication, and electrical measurements of 2D materials for neuromorphic, hardware security, and bio-inspired applications."
        ],
        image: Portfolio1

    }
]


export const ResearchPage = () => {
    return (
        <div>
           <h1 style={{ marginBottom: '20px' }}>Research</h1>
            
            <div className="researchContainer">
                {ResearchExperience.map(research => (
                    <div className="researchContent">
                        <div className="researchText">
                            <h3 className="researchTitle">{research.title}</h3>
                            <i className="timestamp">&#40;{research.timestamp}&#41;</i>
                            <ul className="DescList">
                                {research.description.map((point, index) => (
                                    <li className="DescLi" key={index}>&bull; {point}</li>
                                ))}
                            </ul>
                        </div>
                        <div >
                                <img className="researchImage" src={research.image} alt={`${research.title}`}></img>
                        </div>

                    </div>
                ))}


            </div>
        </div>
    )

}