import ETCAMPUS from "../assets/aditya/etcampusstar.jpg"
import "./styles/Achievements.css"
import MITACS from "../assets/aditya/mitacs.jpg"
import NONTECH from "../assets/aditya/nontechnicalseminar.jpg"
import ACADPROF from "../assets/aditya/academicproficiency.jpg"

const Achievements = [
    {
        name: "MITACS GLOBAL RESEARCH INTERN 2023",
        description: "I was selected for the prestigious MITACS Globalink Research 2023 Program to work under Prof. Roman Genov in his Biomedical Engineering team at the Intelligent Sensory Microsystems Laboratory in the Department of Electrical & Computer Engineering at the University of Toronto. My project involves designing a compact PCB for a multi-domain energy harvester to drive neural implants. Throughout this experience, I've gained proficiency in using EDA tools, micro-soldering minute components, microcontroller coding, and developing artificial debugging setups. I am also eligible to receive a $11,000 scholarship if I wish to pursue my graduate studies in Canada and have also received the opportunity to become a MITACS Globalink Ambassador.",
        image: MITACS
    },
    {
        name: "ET CAMPUS STAR OF INDIA",
        description: "Recognized as Economic Times (ET) Campus Star of India, one among 87 members selected from a cohort of 35000+ engineering students from 2000 colleges across India. This award is presented to the \"Brightest Engineering Minds\" of the country who excel in their intellectual, analytical, managerial and leadership abilities.",
        image: ETCAMPUS
    },
    {
        name: "NON-TECHNICAL SEMINAR",
        description: "Presented a research paper titled “The Temple Architecture: The Real Amalgamation of Science & Spirituality” in a 3-day National Seminar on Historical Development of Indian Temple Architecture and Construction Styles held at Amrita Vishwa Vidyapeetham, Bengaluru Campus. It was organized by Amrita Darshanam International Center for Spiritual Studies & Indian Council of Historical Research, Government of India. The work is also published in the ICHR Seminar Proceedings.",
        image: NONTECH
    },
     {
        name: "ACADEMIC PROFICIENCY PRIZE",
        description: "I received this award for consistently securing the 1st rank in the Department of Electronics and Communication Engineering among 110 students for five consecutive semesters. ",
        image:ACADPROF
    },{
        name: "STUDENT EXCHANGE PROGRAM",
        description: "Currently selected as a student intern to perform my research contributing to my major project under Prof. Saptarshi Das at Penn State University, USA for 6 months. I will be working in the field of characterization and fabrication of 2D material based nanodevices along with conducting simulation of neural networks and several other machine learning algorithms."
    },
]


export const AchievementsPage = () => {
    return (
        <div className="AchcationalQual">
            <div >
                <h1> Achievements    </h1>
            </div>
            <div className="achievement">
                {Achievements.map((achievement, index) => (
                    <div className="AchBox">
                        <article>
                            {achievement.image && (
                            <div className="wrap">
                                <img alt="Achcation_Image" src={achievement.image} className="AchImage" style={{ maxWidth: '20rem', minWidth: "20rem", maxHeight: "fit-content" }}></img>
                            </div>

                            )}
                            <div>
                                <h3 className="AchTitle" style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center"
                                }}>{achievement.name}</h3>
                                
                                <p className="AchDesc" style={{
                                }}>{achievement.description}</p>
                            </div>
                        </article>
                    </div>
                ))}
            </div>

        </div>
    )

}