
import { RouterJS } from "./Routes";
import { inject } from '@vercel/analytics';
// import Navbar from "./components/NavBar/navbar"
function App() {
    inject();
    return (

        <div className="App">
            <RouterJS/>   
                 
        </div>
    );
}

export default App;
