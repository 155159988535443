import React from 'react';
import './works.css';
import { Link } from 'react-router-dom';



const worksData = [
  {
    name: 'Educational Qualifications',
    link:"/education",
  },
  // {
  //   name: 'Paper Publications',
  //   link:'/papers'
  // },
  {
    name: 'International Research Experience',
    link:'/research'
  },
  {
    name: 'Scholastic Achievements',
    link:'/achievements'
  },
  {
    name: 'Startups',
    link: 'startups'
  },
  {
    name: 'Seminars',
    link:'/seminars'
  },
  {
    name: 'Key Projects',
    link: '/projects'
  },
  {
    name: 'Extra Curricular',
    link: '/extracurricular'
  },
];

const Works = () => {

  

    return (
      <section id="works" className='works'>
        <h2 className="worksTitle">My Portfolio</h2>
        <span className="worksDesc">
          My portfolio sums up all my Achievements and Experiences.
          Giving you a brief idea about my journey so far. Also my way of perspective towards problems and their solutions.
        </span>
        <div className="worksBox">
          {worksData.map((work, index) => (
            <div className='worksBoxes'>
            <Link to={work.link}>
              <h3>{work.name}</h3>
            </Link>

            </div>
            ))}
        </div>
        

      </section>
    );
  };
  
  export default Works;