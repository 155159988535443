import React from 'react';
import './skills.css';
import Research from '../../assets/research.png';
import Academics from '../../assets/academics.png';
import Innovation from '../../assets/innovation.png';

const Skills = () => {
    return (
        <section id='skills'>
            <span className="skillTitle">What am I?</span>
            <span className="skillDesc">I am a dedicated and innovative researcher with a passion for exploring cutting-edge technologies and contributing to impactful projects. My expertise lies in multi-domain energy harvesting for medical implants, 2D material-based devices, and wearable textile-integrated sensor network design. I bring a wealth of experience in PCB design, circuit optimization, and hands-on testing under simulated conditions.</span>
            <div className="skillBars">
                <div className="skillBar">
                    <img src={Research} alt="UIDesign" className="skillBarImg" />
                    <div className="skillBarText">
                        <h2>Research and Innovation</h2>
                        <p>Embarking on a Journey of Discovery</p>
                    </div>
                </div>
                <div className="skillBar">
                    <img src={Academics} alt="WebDesign" className="skillBarImg" />
                    <div className="skillBarText">
                        <h2>Academic Excellence</h2>
                        <p>Where Knowledge Meets Achievement</p>
                    </div>
                </div>
                <div className="skillBar">
                    <img src={Innovation} alt="AppDesign" className="skillBarImg" />
                    <div className="skillBarText">
                        <h2>Innovation in Action</h2>
                        <p>From Concept to Creation</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Skills;