import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from 'react-router-dom';
import { EducationPage } from "./pages/Education";
import { HomePage } from "./pages/Home";
import { PaperPublications } from "./pages/PaperPublications";
import { ResearchPage } from "./pages/Research";
import { AchievementsPage } from "./pages/Achievements";
import { StartupsPage } from "./pages/Startups";
import { ProjectsPage } from "./pages/Projects";
import { SeminarsPage } from "./pages/Seminars";
import { ExtraCurricularPage } from "./pages/ExtraCurricular";
import { NavbarLogo } from "./components/NavBar/NavBarLogo"


export const RouterJS = () => {
    return (
        <div>
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/education" element={
                    <div>
                        <NavbarLogo />
                        <EducationPage /> 
                    </div>
                } />
                <Route path="/papers" element={
                    <div>
                        <NavbarLogo />
                        <PaperPublications />
                    </div>
                } />
                <Route path="/projects" element={
                    <div>

                        <NavbarLogo />
                        <ProjectsPage />

                    </div>

                } />
                <Route path="/achievements" element={
                    <div>
                        <NavbarLogo />
                        <AchievementsPage />
                    </div>


                } />
                <Route path="/startups" element={
                    <div>
                        <NavbarLogo />
                        <StartupsPage />
                    </div>

                } />
                <Route path="/research" element={
                    <div>
                        <NavbarLogo />
                        <ResearchPage />
                    </div>


                } />
                <Route path="/seminars" element={
                    <div>
                        <NavbarLogo />
                        <SeminarsPage />
                    </div>
                } />
                <Route path="/extracurricular" element={
                    <div>
                        <NavbarLogo />
                        <ExtraCurricularPage />
                    </div>
                } />
            </Routes>
        </Router>
                </div>
    )
}