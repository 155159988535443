import "./styles/projects.css";
import Portfolio1 from "../assets/portfolio-1.png"

const Projects = [
  {
    title: "Hexa-band frequency monopole re-configurable antenna.",
    course: "Radio Frequency Engineering",
    description: [
      "Conducted a validation of the proposed hexa-band frequency re-configurable antenna using ANSYS Electronics software.",
      'Designed the Antenna and analyzed its performance parameters such as Return Loss, Radiation Patterns, 3D Gain Plot, and VSWR results.'
    ],
    img:Portfolio1
  },
  {
    title: "Terahertz Technology for Tumour detection.",
    course: "Radio Frequency Engineering",
    description: [
      "Studied the usage of Terahertz waves to detect the presence of tumor tissues.",
      "Developed a custom human breast phantom in ANSYS Electronics software by assigning different relative permittivity values to each layer of the phantom.",
      "The s parameters were analyzed to find out the size and density of the tumor."
     ],
    img:Portfolio1
  },
  {
    title: "Ultrasonic Object Detection System.",
    course: "Introduction to Internet of Things",
    description: [
     "Designed a simple hardware system capable of detecting objects in a defined region.",
     "HC-SR04 ultrasonic sensor was mounted on top of a servo motor, the rotational limits were coded, and the sensor was used to scan a specific span.",
     "Processing3 software sketchbook was utilized to infer the output graphically, the presence of an object was displayed as a “red sweep” on the laptop, mimicking the display unit of a Radar."
     ],
    img:Portfolio1
  },
  {
    title: "Hand Gesture Recognition.",
    course: "Computer Vision",
    description: [
      "The objective was to acquire the image of the palm and segment the regions to distinguish the fingers by using landmarks around the region.",
      "The next step was to count the fingers being shown in the camera, considering all constraints.",
      "OpenCV library and MediaPipe framework were adopted to develop the model."
     ],
    img:Portfolio1
  }
];

// ... (imports and Projects array)

export const ProjectsPage = () => {
    return (
      <div>
        <h1 style={{ marginBottom: '20px' }}>Projects</h1>
        <div className="ProjectContainers">
          {Projects.map((project, index) => (
            <div key={index} className="ProjectBox">
              <div className="ProjectImage">
                <img src={project.img} alt={`${project.title}`} />
              </div>
              <div className="ProjectContent">
                <h3 className="ProjectTitle">{project.title}</h3>
                <i className="ProjectCourse"> Course: {project.course}</i>
                <ul className="DescList">
                  {project.description.map((point, index) => (
                    <li className="DescLi" key={index}>{point}</li>
                  ))}
                </ul>
              </div>
              
            </div> 
          ))}
        </div>
      </div>
    );
  };
  