import "./contact.css";
// import Walmart from '../../assets/walmart.png';
// import Adobe from '../../assets/adobe.png';
// import Microsoft from '../../assets/microsoft.png';
// import Facebook from '../../assets/facebook.png';
import linkedInIcon from "../../assets/linkedin.png";
import gmailIcon from "../../assets/gmail.png";
// import youtubeIcon from "../../assets/youtube.png";
// import instagramIcon from "../../assets/instagram.png";
import React from "react";
// import emailjs from '@emailjs/browser';
import Carousel from "./carousel";
import Pic1 from "../../assets/carousel/1.PNG";
import Pic2 from "../../assets/carousel/2.PNG";
import Pic3 from "../../assets/carousel/3.PNG";
import Pic4 from "../../assets/carousel/4.PNG";
import Pic5 from "../../assets/carousel/5.PNG";
// import Pic6 from "../../assets/carousel/6.PNG";
// import Pic7 from "../../assets/carousel/7.PNG";
import Pic8 from "../../assets/carousel/8.PNG";
import Pic9 from "../../assets/carousel/9.PNG";
import Pic10 from "../../assets/carousel/10.PNG";
import Pic11 from "../../assets/carousel/11.PNG";
import Pic12 from "../../assets/carousel/12.PNG";
import Pic13 from "../../assets/carousel/13.PNG";
import Pic14 from "../../assets/carousel/14.PNG";
import Pic15 from "../../assets/carousel/15.PNG";
import Pic16 from "../../assets/carousel/16.PNG";
import Pic17 from "../../assets/carousel/17.PNG";
import Pic18 from "../../assets/carousel/18.PNG";
import Pic19 from "../../assets/carousel/19.PNG";
import Pic20 from "../../assets/carousel/20.PNG";

const Contact = () => {
  // const form = useRef();
  const images = [
    Pic1,
    Pic2,
    Pic3,
    Pic4,
    Pic5,
    // Pic6,
    // Pic7,
    Pic8,
    Pic9,
    Pic10,
    Pic11,
    Pic12,
    Pic13,
    Pic14,
    Pic15,
    Pic16,
    Pic17,
    Pic18,
    Pic19,
    Pic20,
  ];

  // const sendEmail = (e) => {
  //     e.preventDefault();

  //     emailjs.sendForm('service_6phm4ar', 'template_zcxds5a', form.current, 'blaHt_5KIJ711DfGH')
  //         .then((result) => {
  //             console.log(result.text);
  //             e.target.reset();
  //             alert('Email Sent !');
  //         }, (error) => {
  //             console.log(error.text);
  //         });
  // };

  return (
    <div id="contactPage">
      <div id="clients">
        <h1 className="contactPageTitle">My Gallery</h1>
        <span className="clientDesc">
          A glimpse through my gallery of my experiences and happy moments.
        </span>
        <div className="clientImg">
          <Carousel images={images} />
        </div>
      </div>
      {/* <div id="contact">
                <h1 className="contactPageTitle">Contact Me</h1>
                <span className="contactDesc">Please fill out the form below to discuss any work opportunities.</span>
                <form className="contactForm" ref={form} onSubmit={sendEmail}>
                    <input type="text" className="name" placeholder='Your name' name='from_name' />
                    <input type="text" className="email" placeholder='Your Email' name='from_email' />
                    <textarea name="message" placeholder='Your Message' rows={5} className='msg' ></textarea>
                    <button type="submit" value="Send" className='submitBtn'>Submit</button>
                </form>
            </div> */}
      <div className="links">
        <a
          href="http://www.linkedin.com/in/m-v-sai-aditya-012300213"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedInIcon} alt="LinkedIn" className="link" />
        </a>
        <a
          href="mailto:adityamvsai16@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={gmailIcon} alt="Gmail" className="link" />
        </a>

        
        {/* <img src={youtubeIcon} alt="YouTube" className="link" /> */}
        {/* <img src={instagramIcon} alt="Instagram" className="link" /> */}
      </div>
    </div>
  );
};

export default Contact;
