import "./styles/papers.css"
import Portfolio3 from "../assets/portfolio-3.png"


const Papers = [
    {
        title: "Multi-domain Energy Harvester Front-end for driving Neural Implants",
        team: "Roman Genov, M. V. Sai Aditya, Sudip Nag",
        description: "The compact PCB designed by us was good enough in harvesting energy from four different sources and capable of handling parallel computation of incoming voltage signals to yield an aggregate output. The prototype we designed was developed and tested successfully, featuring a minimal form factor.",
        image: Portfolio3

    },
    {
        title: "Potential utilisation of Polydimethylsiloxane(PDMS)-Calcium Titanate composite for energy harvesting",
        team: "RM. V. Sai Aditya, Sreenidhi P. R., Madhav Ramanathan, Himadeep Thota",
        description: "Calcium titanate become derived from calcium carbonate and titanium dioxide, demonstrating robust biocompatibility. This compound served as a composite material in thin film structures blended with PDMS for its bioencapsulation features. Various shapes have been explored for fabricating multiple thin movie systems the use of the blade technique, and their properties have been analyzed through XRD, SEM, and FTIR strategies.",
        image: Portfolio3

    }
]



export const PaperPublications = () => {
    return (
        <div>
            <ul><h1> Paper Publications </h1></ul>
            <div className="Container">
                {Papers.map(researchPaper => (
                    <div className="paperContent">
                        <div className="contentText">
                            <div className="contentTitle">
                                <h2 >{researchPaper.title}</h2>
                            </div>
                            <div  className="contentTeam">
                                <b><i>{researchPaper.team}</i></b>
                            </div>
                            <p className="contentDesc">{researchPaper.description}</p>
                        </div>
                        <div >
                            <img className="contentImage" src={researchPaper.image} alt={`${researchPaper.title}`}></img>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}