
import Intro from '../components/Intro/intro';
import Skills from "../components/Skills/skills";
import Works from "../components/Works/works";
import Contact from "../components/Contact/contact";
import Footer from "../components/Footer/footer";
import Navbar from "../components/NavBar/navbar"

export const HomePage = () => {
    return(
        <div>
            <Navbar/>
            <Intro />
            <Skills />
            <Works />
            <Contact />
            <Footer />
        </div>
    )
}