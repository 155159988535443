import "./styles/Seminars.css"
import Portfolio3 from "../assets/portfolio-3.png"


const Papers = [
    {
        title: "Technical: Wearable Textile-Integrated Sensor Network Design",
        team: "Guide: Prof. Sreedevi K. Menon, Dept. of ECE, Amrita Vishwa Vidyapeetham",
        description: "Presented our review research work on “Wearable Textile-Integrated Sensor Network Design” emphasizing the growing utilization of Body Area Networks (BANs) at ASE Amritapuri Campus.• We described the potential design of a highly flexible sensor that is capable of enduring muscular strain and supports different communication systems like Radio Frequency Identification (RFID) and Near-Field communication (NFC) to relay the information of the biological parameters.• We reviewed the proposal of a battery-free wearable sensor that forms a bodyNET to monitor human physiological signals such as heart rate, breathing patterns, and gait movement.",
        image: Portfolio3

    },
    {
        title: "Non-Technical: Science and spirituality in temple architecture",
        team: "Guide: Prof. Vipina Valsan, Dept. of EEE, Amrita Vishwa Vidyapeetham",
        description: "• Presented a paper titled “The Temple Architecture: The Real Amalgamation of Science and Spirituality” in a 3-day National Seminar on Historical Development of Indian Temple Architecture and Construction Styles held at Amrita Vishwa Vidyapeetham, Bengaluru Campus. • It was organized by Amrita Darshanam International Institute of Spiritual Studies (ICSS), in association with the Indian Council of Historical Research (ICHR), Govt. of India. • I was the 2nd youngest presenter and the work has been published in ICHR proceedings.",
        image: Portfolio3

    }
]



export const SeminarsPage = () => {
    return (
        <div>
            <ul><h1> Seminars </h1></ul>
            <div className="Container">
                {Papers.map(researchPaper => (
                    <div className="paperContent">
                        <div className="contentText">
                            <div className="contentTitle">
                                <h2 >{researchPaper.title}</h2>
                            </div>
                            <div  className="contentTeam">
                                <b><i>{researchPaper.team}</i></b>
                            </div>
                            <p className="contentDesc">{researchPaper.description}</p>
                        </div>
                        <div >
                            {/* <img className="contentImage" src={researchPaper.image} alt={`${researchPaper.title}`}></img> */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}